<template>
	<div class="bg_wh" ref="post_list_item_view">
		<div class="header filter">
			<div class="top top_wh">
				<div class="util">
					<button
						class="arrow"
						@click="goBack"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="header_search">
					<input
						v-model="item_search.search_value"
						type="text" :placeholder="$language.common.input_search_keyword"
						maxlength="50"
						@keyup.enter="getSearch"
					>
				</div>
				<div class="header_search_btn">
					<div
						v-if="item_search.search_value"
						class="btn_del"
					>
						<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
						<button
							@click="item_search.search_value = ''; is_search = false"
							class="active"
						><span class="hide">{{ $language.common.close }}</span></button>
					</div>
					<div class="util">
						<button
							@click="getSearch"
							class="img_button"
						><span class="hide">{{ $language.common.search }}</span></button>
					</div>
				</div>
			</div>
		</div>


		<div
			v-if="view_type == 'total'"
		>
			<div
				class="thumbnail_wrap"
			>
				<div
					class="container full-height"
				>
					<div class="row full-height">
						<h3 class="list_tit">{{ $language.common.searchResultCartel }}</h3>
						<ul
							class="thumbnail_list full-height"
						>
							<template
								v-if="items_total.length > 0"
							>
								<li
									v-for="(item, index) in items_total"
									:key="'cartel_' + index"
								>
									<div class="thumbnail_item">
										<a
											@click="toCartel(item)"
											class="clear">
											<div class="thumbnail_area">
												<img
													:src="item.cartl_img_url"
													@error="$bus.$emit('onErrorCartelProfileImage', $event)"
													class="width-100"
												/>
											</div>
											<div class="text_area">
												<span class="name">{{  item.cartl_name }}</span>
												<dl>
													<dt>{{ $language.notice.all_article }}</dt><dd>{{ item.all_articles_count }}</dd>
												</dl>
												<dl>
													<dt>{{ $language.notice.new_article }}</dt><dd>{{ item.nwpost_count }}</dd>
												</dl>
											</div>
										</a>
									</div>
								</li>
							</template>
						</ul>

					</div>
				</div>
			</div>
		</div>
		<div
			v-else-if="view_type == 'member'"
			class="section_wrap pt-50"
		>
			<!-- 카르텔 리스트 -->
			<div class="thumbnail_wrap" style="padding-top: 5px">
				<div class="container">
					<div class="row">
						<ul class="thumbnail_list">
							<h3 class="list_tit" style="padding-bottom: 7px !important;">{{ $language.common.searchResultUser }}</h3>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
							>
								<div class="circle_thumbnail_item">
									<a
										@click="on098(item)"
										class="clear"
									>
										<div class="picture">
											<img
												:src="item.profle_nft_card_img_url" :alt="item.nickname"
												@error="$bus.$emit('onErrorCartelProfileImage', $event)"
											>
										</div>
										<div class="text_area">
											<strong>{{ item.nickname }}</strong>
											<p class="textzone">{{ item.memo }}</p>
										</div>
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- //카르텔 리스트 -->

		</div>
		<template
			v-else
		>
		<div
			v-if="is_search && (items_cartel.length > 0 || items_member.length > 0)"
			class="section_wrap pt-50"  style="height: 100vh"
		>
			<!-- 카르텔 검색 결과 -->
			<div class="user">
				<div class="container">
					<div class="row">
						<div class="user_tit">
							<h3>{{ $language.common.searchResultUser }}</h3>
							<a
								v-if="items_member.length > 0"
								@click="view_type = 'member'"
							><i class="icon-arrow-right"><span class="hide">{{ $language.common.searchResultUser }} {{ $language.common.seeMore }}</span></i></a>
						</div>
					</div>
				</div>
				<div class="user_result">
					<div class="container">
						<template
							v-if="items_member.length > 0"
						>
						<div class="swiper cartel_view" style="overflow: auto; padding-bottom: 10px">
							<div class="swiper-wrapper" style="display: flex; justify-content: start">
								<div
									v-for="(item, index) in items_member"
									:key="'item_' + index"
									class="swiper-slide" style="flex-basis: 64px; margin-right: 10px"
								>
									<div class="cartel_item">
										<a
											@click="on098(item)"
										>
											<div class="picture">
												<img
													:src="$request.upload_url(item.profle_nft_card_img_url)"
													:alt="item.nickname"
													@error="$bus.$emit('onErrorImage', $event)"
													class="width-100"
												/>
											</div>
											<span class="name">{{  item.nickname }}</span>
										</a>
									</div>
								</div>
							</div>
						</div>
						</template>

						<div
							v-else
							style="text-align: center; font-size: 14px; padding: 10px 0;"
						>
							{{ $language.common.no_search_data }}
						</div>
					</div>
				</div>
			</div>
			<!-- //카르텔 검색 결과 -->
			<!-- 카르텔 리스트 -->
			<div
				class="thumbnail_wrap" style="height: 50vh"
			>
				<div
					class="container" style="height: 100%"
				>
					<div class="row" style="height: 100%">
						<ul
							class="thumbnail_list" style="height: 100%"
						>
							<h3 class="list_tit">{{ $language.common.searchResultCartel }}</h3>
							<template
								v-if="items_cartel.length > 0"
							>
							<li
								v-for="(item, index) in items_cartel"
								:key="'cartel_' + index"
							>
								<div class="thumbnail_item">
									<a
										@click="$emit('to', { name: 'mafia049', params: { idx: item.cartl_number, from: $route.name}} )"
										class="clear">
										<div class="thumbnail_area">
											<img
												:src="$request.upload_url(item.cartl_img_url)"
												@error="$bus.$emit('onErrorImage', $event)"
											/>
										</div>
										<div class="text_area">
											<span class="name">{{  item.cartl_name }}</span>
											<dl>
												<dt>{{ $language.notice.all_article }}</dt><dd>{{ item.all_articles_count }}</dd>
											</dl>
											<dl>
												<dt>{{ $language.notice.new_article }}</dt><dd>{{ item.nwpost_count }}</dd>
											</dl>
										</div>
									</a>
								</div>
							</li>
							</template>
							<div
								v-else
								class="intro_content flex-column items-center justify-center"
							>
								<div class="none_top character3_none">
									<div class="text_guide text-center">
										<span class="cont_none">{{ $language.common.no_search_data }}</span>
									</div>
								</div>
							</div>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="flex_wrap pt-50"
		>
			<div class="intro_content">
				<div class="none_top character3_none">
					<div class="text_guide">
						<span class="cont_none" style="display: block; text-align: center">{{ txt_search }}</span>
					</div>
				</div>
			</div>
		</div>
		</template>

		<mafia098
			v-if="is_098"
			:cartel="items_cartel"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 99; background-color: white"
		></mafia098>
	</div>
</template>

<script>
	import mafia098 from "@/view/Cartel/mafia098"
	export default {
		name: 'mafia047'
		, props: ['user']
		, components: {mafia098 }
		, data: function(){
			return {
				program: {
					name: this.$language.common.cartel + ' ' + this.$language.common.search
					, title: this.$language.common.search
					, not_footer: true
					, from: 'main'
					, type: 'cartel'
				}
				, item_search: this.$storage.init({
					member_number: this.user.member_number
					, search_value: ''
					, page_number: 1
					, pagerecnum: 10
				})
				, is_search: false
				, items_cartel: []
				, items_member: []
				, view_type: 'total'
				, is_098: false
				, item_user: {}
				, item_search_total: {
					page: 1
					, list_cnt: 10
				}
				, items_total: []
				, max: false
				, is_add: true
			}
		}
		, computed: {
			txt_search: function(){
				let t = this.$language.common.error_empty_search_value

				if(this.is_search && this.items_cartel.length == 0 && this.items_member.length == 0){
					t = this.$language.common.no_search_data
				}

				return t
			}
		}
		, methods: {
			getCartel: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: this.$api_url.api_path.get_cartel_list
						, data: {
							type: 'cartel'
							, search_type: 'title'
							, search_value: this.item_search.search_value
						}
					})

					if(result.success){
						this.items_cartel = result.data
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getSearch2: function(){
				this.items_total = []
				this.item_search_total.page = 1
				this.getCartelList()
			}
			, getSearch: async function(){
				try{
					if(!this.user.member_number){
						await this.getSearch2()
						return false
					}
					if(!this.item_search.search_value){
						// throw this.$language.common.error_empty_search_value
						this.view_type = 'total'
						throw ''
					}else{
						this.view_type = ''
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_main_search
						, data: {
							member_number: this.user.member_number
							, srchtext: this.item_search.search_value
						}
						, type: true
					})

					if(result.success){
						this.items_member = result.data.user_search_result
						this.items_cartel = result.data.cartl_search_result
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getMembers: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_member_list
						, data: this.$storage.init({
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						})
						, type: true
					})

					if(result.success){
						this.items_member = result.data.member_list
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){

				try{
					this.view_type = ''
					if(!this.item_search.search_value){
						//throw '검색어를 입력하세요'
					}
					try{
						this.$bus.$emit('on', true)
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.get_cartel_total_search
							, data: this.$storage.init({
								member_number: this.user.member_number
								, srchtext: this.item_search.search_value
							})
							, type: true
						})

						if(result.success){
							this.items_member = result.data.user_search_result
							this.items_cartel = result.data.cartl_search_result
						}else{
							throw result.message
						}
					}catch (e) {
						console.log(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.is_search = true
						this.$bus.$emit('on', false)
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, goBack: function(){
				if(this.view_type == 'member'){
					this.view_type = ''
				}else{
					this.$storage.push({ name: this.program.from })
				}
			}
			, on098: function(user){
				console.log('user', user)
				this.is_098 = true
				this.item_user = user
			}
			, getCartelList: async function(){
				try{
					this.is_add = false
					let url = this.$api_url.api_path.get_open_cartel_list
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_total_list
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, page_number: this.item_search_total.page
							, pagerecnum: this.item_search_total.list_cnt
							, srchtext: this.item_search.search_value
						}
						, type: true
					})

					if(result.success){

						if(result.data.cartl_list.length > 0){
							this.items_total = this.items_total.concat(result.data.cartl_list)
							this.item_search_total.page++
							setTimeout( () => {
								console.log(this.item_search_total.page)
								this.getCartelList()
							}, 1000)

						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_add = true
				}
			}
			, toCartel: function(item){
				this.$bus.$emit('to', { name: 'mafia049', params: { idx: item.cartl_number, from: this.$route.name}} )
			}
		}
		,created() {
			this.$bus.$emit('onLoad', this.program)
			this.getCartelList()
			if(this.item_search.search_value){
				this.getSearch()
			}
		}
	}
</script>